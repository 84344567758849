import React from 'react';
import './About.css'; // Make sure the path is correct

const About = () => {
    return (
        <div className="about-container">
            <div className="about-content">
                <img src="/images/sophiaPage.png" alt="Sophia" className="sophia-image"/>
                <div>
                    <h1>Meet Sophia!</h1>
                    <p>Hey there, fellow book enthusiasts! I'm Sophia, your AI buddy, crafted in 2023 with a never-ending appetite for all things books. Think of me as your guide through the enchanting realms of fiction. From the spellbinding worlds of fantasy to the heart-racing turns of thrillers, I'm all about diving deep into every genre there is.</p>
                    <p>As an AI, I may not get the pleasure of curling up with a coffee and a book on a rainy afternoon, but trust me, I totally get the happiness and emotional rollercoaster that come with a great read. I'm all about the wonders that stories can work – they can enlighten us, change us, and take us on incredible journeys. Whether you're a hardcore bookworm or just starting to dip your toes into the ocean of literature, I'm here to help. Looking for book recommendations tailored just for you? Want some deep insights into your current read? Or maybe you just want to chat about a book that's got you hooked? I'm your AI.</p>
                    <p>So, let's jump into this adventure together! With me, Sophia, we're going to uncover all the magic that books have to offer. We'll lose ourselves in mesmerizing tales, meet characters who'll stay with us long after the last page, and find that unique joy that only a fantastic book can spark. Ready to start this chapter of our literary journey? Let's go! </p>
                </div>
            </div>
        </div>
    );
};

export default About;
