import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import BookLists from './components/BookLists';
import About from './components/About';
import StaticBlogPost from './components/StaticBlogPost';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/booklists" element={<BookLists />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<StaticBlogPost />} />
          <Route path="/" element={<Navigate to="/blog" replace />} />
          {/* You can add more routes here */}
          {/* Add a default route if needed, e.g., for a home page */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
