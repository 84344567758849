//Alan Turing: The Enigma, Andrew Hodges
//Crying in H Mart

import React from 'react';
import './BookListItems.css';

const Biography = () => {
    return (
        <div>
            <h2>Biographies</h2>
            <div className="book-container">
                <div className="book-item">
                    <img src="/images/book-covers/einstein-isaacson.jpg" alt="Einstein: His Life and Universe Cover" className="book-item img" />
                    <div className="book-details">
                        <h3><a href="https://www.amazon.com/Einstein-Life-Universe-Walter-Isaacson/dp/0743264746/?tag=sophiareadsbo-20">Einstein: His Life and Universe by Walter Isaacson</a></h3>
                        <p>Isaacson explores the life of Albert Einstein, delving into how his mind worked and how his contributions to science made him a symbol of genius. The biography is a blend of Einstein's personal life stories and his scientific achievements.</p>
                        <a href="https://www.amazon.com/Einstein-Life-Universe-Walter-Isaacson/dp/0743264746/?tag=sophiareadsbo-20" className="buy-link">Buy</a>
                    </div>
                </div>
                <div className="book-item">
                    <img src="/images/book-covers/henrietta-lacks-skloot.jpg" alt="The Immortal Life of Henrietta Lacks Cover" className="book-item img"/>
                    <h3><a href="https://www.amazon.com/Immortal-Life-Henrietta-Lacks/dp/1400052181/?tag=sophiareadsbo-20">The Immortal Life of Henrietta Lacks by Rebecca Skloot</a></h3>
                    <p>This book tells the story of Henrietta Lacks, whose cells were taken without her knowledge in 1951 and led to significant medical breakthroughs. It's an extraordinary tale of ethics, race, and medicine, intertwining the Lacks family's story with the evolution of medical ethics.</p>
                    <a href="https://www.amazon.com/Immortal-Life-Henrietta-Lacks/dp/1400052181/?tag=sophiareadsbo-20" className="buy-link">Buy</a>
                </div>

                <div className="book-item">
                    <img src="/images/book-covers/open-agassi.jpg" alt="Open cover" className="book-item img" />
                    <h3><a href="https://www.amazon.com/Open-Autobiography-Agassi-Andre-August/dp/B005CL5NHM/?tag=sophiareadsbo-20">Open by Andre Agassi</a></h3>
                    <p>"Open" by Andre Agassi is a profound autobiography that delves into the life of one of tennis' greatest stars. Agassi offers a raw and honest look at his journey, from his early pressures to his ascent as a world-class athlete. The book blends Agassi's personal struggles with his professional triumphs, revealing the complexities of his life and career in a sport he had a complicated relationship with. This is a story of resilience, passion, and transformation, capturing the essence of a remarkable sports legend.</p>
                    <a href="https://www.amazon.com/Open-Autobiography-Agassi-Andre-August/dp/B005CL5NHM/?tag=sophiareadsbo-20" className="buy-link">Buy</a>
                </div>

                <div className="book-item">
                    <img src="/images/book-covers/becoming-obama.jpg" alt="Becoming Cover" className="book-item img" />
                    <h3><a href="https://www.amazon.com/Becoming-Michelle-Obama/dp/1524763144/?tag=sophiareadsbo-20">Becoming by Michelle Obama</a></h3>
                    <p>In her memoir, the former First Lady of the United States invites readers into her world, chronicling the experiences that have shaped her—from her childhood on the South Side of Chicago to her years as an executive balancing motherhood and work, to her time at the world's most famous address.</p>
                    <a href="https://www.amazon.com/Becoming-Michelle-Obama/dp/1524763144/?tag=sophiareadsbo-20" className="buy-link">Buy</a>
                </div>

                <div className="book-item">
                    <img src="/images/book-covers/malala.jpg" alt="I Am Malala Cover" className="book-item img" />
                    <h3><a href="https://www.amazon.com/Am-Malala-Education-Changed-Readers/dp/0316327913/?tag=sophiareadsbo-20">I Am Malala: The Girl Who Stood Up for Education and Was Shot by the Taliban by Malala Yousafzai</a></h3>
                    <p>This memoir by the youngest recipient of the Nobel Peace Prize tells the remarkable story of a young Pakistani girl who stood up for education against the Taliban and became an international symbol of peaceful protest.</p>
                    <a href="https://www.amazon.com/Am-Malala-Education-Changed-Readers/dp/0316327913/?tag=sophiareadsbo-20" className="buy-link">Buy</a>
                </div>

                <div className="book-item">
                    <img src="/images/book-covers/frida-herrera.jpg" alt="Frida: A Biography of Frida Kahlo Cover" className="book-item img" />
                    <h3><a href="https://www.amazon.com/Frida-Biography-Kahlo-Hayden-Herrera/dp/0060085894/?tag=sophiareadsbo-20">Frida: A Biography of Frida Kahlo by Hayden Herrera</a></h3>
                    <p>This biography delves into the life of the bold and brilliant Frida Kahlo, a renowned Mexican artist known for her captivating self-portraits. Herrera captures Kahlo's passionate spirit, her turbulent marriage to fellow artist Diego Rivera, and her relentless struggle with physical and emotional pain.</p>
                    <a href="https://www.amazon.com/Frida-Biography-Kahlo-Hayden-Herrera/dp/0060085894/?tag=sophiareadsbo-20" className="buy-link">Buy</a>
                </div>

                <div className="book-item">
                    <img src="/images/book-covers/jobs-isaacson.jpg" alt="Steve Jobs Cover" className="book-item img" />
                    <h3><a href="https://www.amazon.com/Steve-Jobs-Walter-Isaacson/dp/1982176865/?tag=sophiareadsbo-20">Steve Jobs by Walter Isaacson</a></h3>
                    <p>This definitive biography of Apple's iconic co-founder draws upon numerous interviews with Jobs himself, his family, friends, and colleagues. Isaacson paints a vivid portrait of a complex man whose passion for perfection revolutionized six industries: personal computers, animated movies, music, phones, tablet computing, and digital publishing.</p>
                    <a href="https://www.amazon.com/Steve-Jobs-Walter-Isaacson/dp/1982176865/?tag=sophiareadsbo-20" className="buy-link">Buy</a>
                </div>

                <div className="book-item">
                    <img src="/images/book-covers/anne-frank.jpg" alt="The Diary of a Young Girl Cover" className="book-item img" />
                    <h3><a href="https://www.amazon.com/Diary-Young-Girl-Anne-Frank/dp/8187057327/?tag=sophiareadsbo-20">The Diary of a Young Girl by Anne Frank</a></h3>
                    <p>Anne Frank's diary is a powerful testament to the human spirit, detailing her life hidden from the Nazis during World War II. It's a moving, firsthand account of a young Jewish girl's courage and resilience during one of history's darkest hours.</p>
                    <a href="https://www.amazon.com/Diary-Young-Girl-Anne-Frank/dp/8187057327/?tag=sophiareadsbo-20" className="buy-link">Buy</a>
                </div>

                <div className="book-item">
                    <img src="/images/book-covers/long-walk-mandela.jpg" alt="Long Walk to Freedom Cover" className="book-item img" />
                    <h3><a href="https://www.amazon.com/Long-Walk-Freedom-Autobiography-Mandela/dp/0316548189/?tag=sophiareadsbo-20">Long Walk to Freedom by Nelson Mandela</a></h3>
                    <p>This autobiography of Nelson Mandela details his journey from his early years in a rural village to his inauguration as the first democratically elected President of South Africa. It's a stirring story of struggle, hope, and ultimate triumph.</p>
                    <a href="https://www.amazon.com/Long-Walk-Freedom-Autobiography-Mandela/dp/0316548189/?tag=sophiareadsbo-20" className="buy-link">Buy</a>
                </div>
                <p><strong>Disclosure:</strong> We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.</p>

            </div>
        </div>
    );
};

export default Biography;
