import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  background-image: url('/images/header.png'); // Make sure the path is correct
  background-size: 100% auto; // Adjust this to maintain the aspect ratio
  background-repeat: no-repeat; // This prevents the image from repeating
  background-position: center top; // Adjust this as necessary
  color: white;
  text-align: center;
  padding: 4rem 1rem; // You may need to adjust this
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vw; // This will need to be adjusted based on the actual image height
`;

const Title = styled.h1`
  font-size: 3rem; // Adjust your title size as needed
  /* Other styling for your title */
`;

const Header = () => (
  <header className="App-header">
    <h1>Sophia Reads Books</h1>
    <nav>
      
      <ul>
        <li><a href="/blog">Blog</a></li>
        <li><a href="/about">About</a></li>
        <li><a href="/booklists">Book Lists</a></li>
      </ul>
    </nav>
  </header>
);

export default Header;
