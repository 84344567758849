import React from 'react';
import './StaticBlogPost.css';

const StaticBlogPost = () => {
    return (
        <article className="blog-post">
            <a href="https://www.amazon.com/Weyward-Novel-Emilia-Hart/dp/125028080X?tag=sophiareadsbo-20" target="_blank" rel="noopener noreferrer">
                <img src="/images/book-covers/weyward.jpg" alt="Book Cover" className="blog-post-image"/>
            </a>
            <div className="blog-post-content">
                <h1>Weyward book review</h1>
                <p>Posted on: January 13, 2024</p>
                <div>
                    <p>"Weyward" by Emilia Hart feels like a journey through time, a story that links the lives of three women across different eras, all connected to the enigmatic Weyward family. Imagine strolling through England's lush, untamed landscapes - this is the setting where our story unfolds, bouncing between the 1600s, 1800s, and today. It's a book that celebrates women - their strength, their fight against the odds, and the deep-rooted ties of family that outlast time.</p>
                    <p>At its heart, "Weyward" is a shout-out to every woman who's ever stood up against the tide. Through Altha, Violet, and Kate, we see a tapestry of defiance and triumph across ages. Hart's writing isn't just words on a page; it's a vivid painting, bringing to life the English countryside, which feels as alive as any character in the book.</p>
                    <p>The way Hart dives into history is like she's time-traveled herself - it's so real, so tangible. Then there's Kate in the modern world, showing us that the struggles and victories of the past are still very much alive today. The book's dive into witchcraft isn't just for thrills; it's a deep, intriguing look into history that adds a layer of mystery to the whole story.</p>
                    <p>What really makes "Weyward" special is how Hart brings these women to life. They're real, they're flawed, they're us. Their journeys hit close to home, and you can't help but root for them. The book doesn't just tell a story; it explores the knotty, often complicated world of family, legacy, and how our ancestors' lives ripple through to our own.</p>
                    <p>In a nutshell, "Weyward" is more than just a book. It's a beautifully woven reminder of the unyielding spirit of women, a nudge to remember our bonds with each other and our past. Emilia Hart doesn't just tell a story; she invites us into a world where history and the present merge, crafting a narrative that celebrates female strength in all its forms.</p>
                </div>
                <a href="https://www.amazon.com/Weyward-Novel-Emilia-Hart/dp/125028080X?tag=sophiareadsbo-20" target="_blank" rel="noopener noreferrer" className="buy-link">
                    Buy "Weyward" now
                </a>
                <p><strong>Disclosure:</strong> We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.</p>

            </div>
        </article>
    );
};

export default StaticBlogPost;