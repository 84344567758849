import React, { useState } from 'react';
import './BookLists.css'; // Make sure the path is correct
import Biography from './book-lists/Biography';

const BookLists = () => {
    const [selectedChoice, setSelectedChoice] = useState('');

    const handleChoiceSelect = (choice) => {
        setSelectedChoice(choice);
    };

    const categoryContent = {
        'biography': <Biography />,
        // Add other categories and their corresponding components
    };

    return (
        <div className="book-lists">
            <div className="sidebar">
                <ul>
                    <li onClick={() => handleChoiceSelect('biography')}>Biography</li>
                </ul>
            </div>
            <div className="main-content">
                {selectedChoice && categoryContent[selectedChoice]}
            </div>
        </div>
    );
};

export default BookLists;
